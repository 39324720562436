<template>
    <div class="wrapper -productos padding-menu -fondo-campo">

        <div class="breadcrumb">
            <div class="container row-end">
                <router-link to="/">Grupo IAN</router-link>
                <router-link :to="$route.path">{{ $t('navbar_menu.products.title') }}</router-link>
            </div>
        </div>

        <section class="intro-page ">
            <div class="container">
                <h1>{{ $t('navbar_menu.products.title') }}</h1>
            </div>
        </section>

        <section class="productos ">
            <div class="container row-start">
                <article class="producto-el waypoints" effect="animate__fadeInUp"
                    v-for="(option, key) in $t('categorias_productos')" :key='key'
                    :style="`background-image:url(/img/productos/${ $t(`categorias_productos['${key}']`).id }.jpg)`">
                    <h2 class="link-arrow waypoints" effect="animate__fadeInUp">{{ option.name }} </h2>
                    <router-link class="enlace-cover" :to="'/' + $t('paths.ficha-categoria-producto') + '/' +  $t('paths.' + option.id + '')"></router-link>
                    </article>
                </div>
            </section>
        </div>
</template>

<script>
export default {
    name: 'calidad-e-innovacion',
}
</script>

<style lang="scss" scoped>
.intro-page {
    margin-bottom: 0;
}

.intro-page .container {
    position: relative;

    h2 {
        margin-bottom: 60px;
    }
}

.intro-page .green-box {
    position: absolute;
    left: 0;
    top: 100px;
    max-width: 800px;
    padding: 15px;
    z-index: 3;
}

.intro-page .contenedor-marcas {
    background: url(/img/background-certificaciones.jpg) center/cover no-repeat;
    padding-top: 120px;
    height: auto;
    padding-bottom: 80px;
    display: inline-block;

    nav.container {
        position: relative;
        left: auto;
        bottom: auto;
        transform: none;
    }
}
</style>